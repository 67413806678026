



















































import Vue from 'vue'
import TrackList from '@/library/track/TrackList.vue'
import { Album } from '@/shared/api'

export default Vue.extend({
  components: {
    TrackList,
  },
  props: {
    id: { type: String, required: true }
  },
  data() {
    return {
      album: null as null | Album,
    }
  },
  computed: {
    isFavourite(): boolean {
      return !!this.$store.state.favourites.albums[this.id]
    }
  },
  async created() {
    this.album = await this.$api.getAlbumDetails(this.id)
  },
  methods: {
    play() {
      if (this.album) {
        return this.$store.dispatch('player/playTrackList', {
          tracks: this.album.tracks,
        })
      }
    },
    setNextInQueue() {
      if (this.album) {
        return this.$store.dispatch('player/setNextInQueue', this.album.tracks)
      }
    },
    addToQueue() {
      if (this.album) {
        return this.$store.dispatch('player/addToQueue', this.album.tracks)
      }
    },
    toggleFavourite() {
      return this.$store.dispatch('favourites/toggle', { id: this.id, type: 'album' })
    },
  }
})
