



















import Vue from 'vue'

export default Vue.extend({
  props: {
    track: { type: Object, required: true },
  },
  computed: {
    isFavourite(): boolean {
      return !!this.$store.state.favourites.tracks[this.track.id]
    }
  },
  methods: {
    toggleFavourite() {
      return this.$store.dispatch('favourites/toggle', { id: this.track.id, type: 'track' })
    },
    download() {
      window.location.href = this.$api.getDownloadUrl(this.track.id)
    },
    setNextInQueue() {
      return this.$store.dispatch('player/setNextInQueue', [this.track])
    },
    addToQueue() {
      return this.$store.dispatch('player/addToQueue', [this.track])
    },
  }
})
