






















import Vue from 'vue'
import CreatePlaylistBtn from '@/playlist/CreatePlaylistBtn.vue'

export default Vue.extend({
  components: {
    CreatePlaylistBtn
  },
  data() {
    return {
      playlistName: '',
      showModal: false,
    }
  },
  computed: {
    playlists() {
      return this.$store.state.playlists.slice(0, 10)
    },
  },
  methods: {
    onDrop(playlistId: string, event: any) {
      event.preventDefault()
      const trackId = event.dataTransfer.getData('id')
      return this.$store.dispatch('addTrackToPlaylist', { playlistId, trackId })
    },
    onDragover(event: any) {
      event.preventDefault()
    },
  }
})
