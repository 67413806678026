













import Vue from 'vue'
import VueSlider from 'vue-slider-component'

export default Vue.extend({
  components: {
    VueSlider,
  },
  props: {
    value: { type: Number, required: true },
  },
  methods: {
    onInput(value: number) {
      this.$emit('input', value)
    },
  }
})
