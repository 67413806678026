















import Vue from 'vue'
import AlbumList from '@/library/album/AlbumList.vue'
import { Album } from '@/shared/api'

export default Vue.extend({
  components: {
    AlbumList,
  },
  data() {
    return {
      loading: true as boolean,
      result: {
        'recently-added': [] as Album[],
        'recently-played': [] as Album[],
        'most-played': [] as Album[],
        random: [] as Album[],
      },
    }
  },
  computed: {
    sections(): any[] {
      return [
        { name: 'Most played', key: 'most-played' },
        { name: 'Recently added', key: 'recently-added' },
        { name: 'Recently played', key: 'recently-played' },
        { name: 'Random', key: 'random' },
      ]
    },
  },
  created() {
    const size = 10
    this.$api.getAlbums('recently-added', size).then((result) => {
      this.result['recently-added'] = result
      this.loading = false
    })
    this.$api.getAlbums('recently-played', size).then((result) => {
      this.result['recently-played'] = result
    })
    this.$api.getAlbums('most-played', size).then((result) => {
      this.result['most-played'] = result
    })
    this.$api.getAlbums('random', 50).then((result) => {
      this.result.random = result
    })
  },
})
