














import Vue from 'vue'

export default Vue.extend({
  props: {
    text: { type: String, default: '' },
    icon: { type: String, default: null },
    variant: { type: String, default: '' },
  }
})
