












import TopNav from '@/app/TopNav.vue'
import Sidebar from '@/app/Sidebar.vue'
import Player from '@/player/Player.vue'

export default {
  components: {
    TopNav,
    Sidebar,
    Player,
  },
}
