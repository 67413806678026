



















import Vue from 'vue'

export default Vue.extend({
  data() {
    return {
      name: '',
      showModal: false,
    }
  },
  computed: {
    playlists() {
      return this.$store.state.playlists.slice(0, 10)
    },
  },
  methods: {
    createPlaylist() {
      const name = this.name
      this.name = ''
      this.showModal = false
      return this.$store.dispatch('createPlaylist', name)
    },
  }
})
