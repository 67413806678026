










































































































import Vue from 'vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import ProgressBar from '@/player/ProgressBar.vue'

export default Vue.extend({
  components: {
    ProgressBar,
  },
  computed: {
    ...mapState('player', {
      isPlaying: (state: any) => state.isPlaying,
      currentTime: (state: any) => state.currentTime,
      repeatActive: (state: any) => state.repeat,
      shuffleActive: (state: any) => state.shuffle,
      muteActive: (state: any) => state.mute,
      visible: (state: any) => state.queue.length > 0,
      volume: (state: any) => state.volume,
    }),
    ...mapGetters('player', [
      'track',
      'progress',
    ]),
    isFavourite(): boolean {
      return this.track && !!this.$store.state.favourites.tracks[this.track.id]
    },
  },
  watch: {
    track: {
      immediate: true,
      handler(track: any) {
        document.title = [track?.title, track?.artist || track?.album, 'Airsonic (refix)']
          .filter(x => !!x).join(' • ')
      }
    }
  },
  methods: {
    ...mapActions('player', [
      'playPause',
      'next',
      'previous',
      'toggleRepeat',
      'toggleShuffle',
      'toggleMute',
      'seek',
    ]),
    setVolume(volume: any) {
      return this.$store.dispatch('player/setVolume', parseFloat(volume))
    },
    toggleFavourite() {
      return this.$store.dispatch('favourites/toggle', { id: this.track.id, type: 'track' })
    },
  }
})
