






























import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import CellDuration from '@/library/track/CellDuration.vue'
import CellArtist from '@/library/track/CellArtist.vue'
import CellAlbum from '@/library/track/CellAlbum.vue'
import CellTrackNumber from '@/library/track/CellTrackNumber.vue'
import CellActions from '@/library/track/CellActions.vue'
import CellTitle from '@/library/track/CellTitle.vue'
import BaseTable from '@/library/track/BaseTable.vue'
import BaseTableHead from '@/library/track/BaseTableHead.vue'

export default Vue.extend({
  components: {
    BaseTableHead,
    BaseTable,
    CellTitle,
    CellActions,
    CellTrackNumber,
    CellAlbum,
    CellArtist,
    CellDuration,
  },
  props: {
    tracks: { type: Array, required: true },
    noAlbum: { type: Boolean, default: false },
    noArtist: { type: Boolean, default: false },
    noDuration: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters({
      playingTrackId: 'player/trackId',
      isPlaying: 'player/isPlaying',
    }),
  },
  methods: {
    ...mapActions({
      playPause: 'player/playPause',
    }),
    play(index: number) {
      if ((this.tracks as any)[index].id === this.playingTrackId) {
        return this.$store.dispatch('player/playPause')
      }
      return this.$store.dispatch('player/playTrackList', {
        index,
        tracks: this.tracks,
      })
    },
    dragstart(id: string, event: any) {
      event.dataTransfer.setData('id', id)
    },
  }
})
