






















import Vue from 'vue'
import { mapState } from 'vuex'
import CreatePlaylistBtn from '@/playlist/CreatePlaylistBtn.vue'

export default Vue.extend({
  components: {
    CreatePlaylistBtn,
  },
  computed: {
    ...mapState(['playlists']),
  },
})
