























import Vue from 'vue'
import { mapGetters } from 'vuex'
import CellTrackNumber from '@/library/track/CellTrackNumber.vue'
import CellActions from '@/library/track/CellActions.vue'
import CellDuration from '@/library/track/CellDuration.vue'
import CellTitle from '@/library/track/CellTitle.vue'
import BaseTable from '@/library/track/BaseTable.vue'
import BaseTableHead from '@/library/track/BaseTableHead.vue'

export default Vue.extend({
  components: {
    BaseTableHead,
    BaseTable,
    CellTitle,
    CellDuration,
    CellActions,
    CellTrackNumber
  },
  props: {
    id: { type: String, required: true },
  },
  data() {
    return {
      podcast: null as null | any,
    }
  },
  computed: {
    ...mapGetters({
      playingTrackId: 'player/trackId',
      isPlaying: 'player/isPlaying',
    }),
  },
  async created() {
    this.podcast = await this.$api.getPodcast(this.id)
  },
  methods: {
    async play(track: any) {
      if (!track.playable) {
        return
      }
      const tracks = this.podcast.tracks.filter((x: any) => x.playable)
      const index = tracks.findIndex((x: any) => x.id === track.id)
      return this.$store.dispatch('player/playTrackList', {
        index,
        tracks,
      })
    },
  }
})
