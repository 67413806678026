













import Vue from 'vue'

export default Vue.extend({
  data() {
    return {
      items: [],
    }
  },
  async created() {
    this.items = await this.$api.getGenres()
  },
})
