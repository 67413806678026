










































import Vue from 'vue'
import { mapState, mapMutations, mapGetters } from 'vuex'
import TrackList from '@/library/track/TrackList.vue'
import BaseTable from '@/library/track/BaseTable.vue'
import BaseTableHead from '@/library/track/BaseTableHead.vue'
import CellTrackNumber from '@/library/track/CellTrackNumber.vue'
import CellDuration from '@/library/track/CellDuration.vue'
import CellAlbum from '@/library/track/CellAlbum.vue'
import CellArtist from '@/library/track/CellArtist.vue'
import CellTitle from '@/library/track/CellTitle.vue'
import CellActions from '@/library/track/CellActions.vue'

export default Vue.extend({
  components: {
    CellActions,
    CellTitle,
    CellArtist,
    CellAlbum,
    CellDuration,
    CellTrackNumber,
    BaseTableHead,
    BaseTable,
    TrackList,
  },
  computed: {
    ...mapState('player', {
      tracks: 'queue',
      queueIndex: 'queueIndex',
    }),
    ...mapGetters('player', {
      isPlaying: 'isPlaying',
    }),
  },
  methods: {
    ...mapMutations('player', {
      remove: 'removeFromQueue',
      clear: 'clearQueue',
    }),
    play(index: number) {
      if (index === this.queueIndex) {
        return this.$store.dispatch('player/playPause')
      }
      return this.$store.dispatch('player/playTrackList', {
        index,
        tracks: this.tracks,
      })
    },
    dragstart(id: string, event: any) {
      event.dataTransfer.setData('id', id)
    },
  }
})
