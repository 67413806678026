
































import Vue from 'vue'

export default Vue.extend({
  props: {
    items: { type: Array, required: true },
  },
  computed: {
    favourites(): any {
      return this.$store.state.favourites.albums
    },
  },
  methods: {
    async playNow(id: string) {
      const album = await this.$api.getAlbumDetails(id)
      return this.$store.dispatch('player/playTrackList', {
        tracks: album.tracks,
      })
    },
    async playNext(id: string) {
      const album = await this.$api.getAlbumDetails(id)
      return this.$store.dispatch('player/setNextInQueue', album.tracks)
    },
    async playLater(id: string) {
      const album = await this.$api.getAlbumDetails(id)
      return this.$store.dispatch('player/addToQueue', album.tracks)
    },
    toggleFavourite(id: string) {
      return this.$store.dispatch('favourites/toggle', { id, type: 'album' })
    }
  }
})
